<template>
  <div style="min-height: 100vh">
    <titleLink title="信息查询" :onClick="onClickBack"></titleLink>
    <div style="padding: 0 0.2rem 0 0.2rem">
      <card style="margin-top: 0.3rem" v-if="tour.id != undefined">
        <template #content> 当前目的地：{{ tour.end_addr }} </template>
      </card>
    </div>
    <van-row class="tabs">
      <van-col
        span="12"
        v-for="(item, index) in tabs"
        :key="index"
        :class="item.type == type ? 'tab-item-active' : 'tab-item'"
        @click="onTabClick(item)"
        >{{ item.text }}</van-col
      >
    </van-row>
    <div class="list_container">
      <van-pull-refresh
        v-model="loading"
        @refresh="searchAround"
        style="padding-bottom: 2rem"
      >
      <template #loading>
        <span style="color:#fff">正在获取当前位置...</span>
      </template>
        <card v-for="(item, idx) in list" :key="idx" @click.native="toMapPage(item)">
          <template #content>
            <van-row>
              <van-col span="24" style="line-height: 20px">
                <div>
                  <span class="list_title">
                    {{ item.name }}
                  </span>
                </div>
              </van-col>
              <van-col
                span="24"
                class="list_content"
                v-if="item.tel.length > 0"
              >
                <span> 电话：{{ item.tel }} </span>
              </van-col>
              <van-col span="24" class="list_content">
                距离：{{ item.distance }}m
              </van-col>
              <van-col span="24" class="list_content">
                地址：{{ item.pname }}{{ item.cityname }}{{ item.adname
                }}{{ item.address.length == 0? '':item.address }}
              </van-col>
            </van-row>
          </template>
        </card>
        <van-empty
          description="没有数据"
          v-if="(list == undefined || list.length == 0) && loading == false"
        />
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink";
import { mapGetters } from "vuex";
import { Toast } from "vant";
import api from "@/services/amap.service";
export default {
  components: {
    card,
    titleLink,
  },
  data() {
    return {
      locationStr: "",
      tabs: [
        {
          text: "附近派出所",
          keywords: "派出所",
          type: 1,
        },
        {
          text: "附近避难所",
          keywords: "避难所",
          type: 2,
        },
      ],
      type: 1,
      currentTab: {},
      query: {
        key: "9c34bb2bd0632fd9d3a9f68c888898e2",
        keywords: "派出所",
        location: "",
      },
      list: [],
      loading: false,
      isiOS: false,
    };
  },
  computed: {
    ...mapGetters("travel_list", {
      tour: "onGoingTours",
    }),
  },
  created() {
    this.currentTab = this.tabs[0];
  },
  mounted() {
    this.$store.dispatch("travel_list/getAllTravelLists", 1);
    if (!("geolocation" in navigator)) {
      Toast("位置服务不可用");
      return;
    }

    var u = navigator.userAgent;//判断是android系统还是IOS系统
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if(this.isiOS) {
      this.nativeGeoLocation()
    } else {
      this.getLocation()
    }
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
    onTabClick(item) {
      if(!this.loading) {
        this.type = item.type;
        this.currentTab = item;
        this.searchAround();
      }
    },
    searchAround() {
      const _self = this;
      this.loading = true;
      const query = {
        keywords: this.currentTab.keywords,
        location: this.locationStr,
        key: this.query.key,
      };
      api.search_around(query).then((res) => {
        _self.loading = false;
        if (res.status === 200 && res.data) {
          _self.list = res.data.pois;
        }
      });
    },
    getLocation() {
      const self = this;
      this.loading = true
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        }); 

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          // data是具体的定位信息
          console.log("定位成功信息：", data);
          self.loading = true
          self.locationStr = `${data.position.lng},${data.position.lat}`
          self.searchAround()
        }

        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
          Toast(data)
        }
      });
    },
    getLngLatLocation() {
      const _self = this
      this.loading = true
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
              });

               _self.locationStr = result.rectangle.split(";")[0];
               _self.searchAround()
            });
          }
        });
      });
    },
    nativeGeoLocation() {
      const _self = this
      navigator.geolocation.getCurrentPosition((position) => {
        _self.locationStr = `${position.coords.longitude},${position.coords.latitude}`
        _self.searchAround()
      })
    },
    toMapPage(item) {
      this.$router.push({path: '/xinxi/map', query: {
        location: item.location,
        name: item.name,
        tel: item.tel.length != 0?item.tel:'',
        current_location: this.locationStr,
        addr: `${item.pname }${ item.cityname }${ item.adname
                    }${ item.address }`
      }})
    }
  },
};
</script>

<style scoped>
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.list_title {
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.list_content {
  font-size: 12px;
  color: #666666;
}
.list_container {
  height: 95vh;
  overflow-y: hidden;
  overflow: scroll;
  padding: 0.5rem 0.2rem 3rem 0.2rem;
}
</style>