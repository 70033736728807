import axios from 'axios' // 引入axios
const request = axios.create({
    baseURL: `https://restapi.amap.com/v3/`,
    timeout: 5000,
    withCredentials: false,
})
const apis = {
  search_around(params) {
    return request({
      url: 'place/around',
      method: 'get',
      params: params
    })
  },
  webServiceKey: '9c34bb2bd0632fd9d3a9f68c888898e2',
  jsApiKey: '74bfabca263c5d85fc9d7459d275457e'
}

export default apis